import _util from "util";
import _toRegex from "to-regex";
import _extendShallow from "extend-shallow";
import _compilers from "./lib/compilers";
import _parsers from "./lib/parsers";
import _cache from "./lib/cache";
import _utils from "./lib/utils";
var exports = {};

/**
 * Module dependencies
 */
var util = _util;
var toRegex = _toRegex;
var extend = _extendShallow;
/**
 * Local dependencies
 */

var compilers = _compilers;
var parsers = _parsers;
var cache = _cache;
var utils = _utils;
var MAX_LENGTH = 1024 * 64;
/**
 * The main function takes a list of strings and one or more
 * glob patterns to use for matching.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm(list, patterns[, options]);
 *
 * console.log(nm(['a.js', 'a.txt'], ['*.js']));
 * //=> [ 'a.js' ]
 * ```
 * @param {Array} `list` A list of strings to match
 * @param {String|Array} `patterns` One or more glob patterns to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Array} Returns an array of matches
 * @summary false
 * @api public
 */

function nanomatch(list, patterns, options) {
  patterns = utils.arrayify(patterns);
  list = utils.arrayify(list);
  var len = patterns.length;

  if (list.length === 0 || len === 0) {
    return [];
  }

  if (len === 1) {
    return nanomatch.match(list, patterns[0], options);
  }

  var negated = false;
  var omit = [];
  var keep = [];
  var idx = -1;

  while (++idx < len) {
    var pattern = patterns[idx];

    if (typeof pattern === "string" && pattern.charCodeAt(0) === 33
    /* ! */
    ) {
        omit.push.apply(omit, nanomatch.match(list, pattern.slice(1), options));
        negated = true;
      } else {
      keep.push.apply(keep, nanomatch.match(list, pattern, options));
    }
  } // minimatch.match parity


  if (negated && keep.length === 0) {
    if (options && options.unixify === false) {
      keep = list.slice();
    } else {
      var unixify = utils.unixify(options);

      for (var i = 0; i < list.length; i++) {
        keep.push(unixify(list[i]));
      }
    }
  }

  var matches = utils.diff(keep, omit);

  if (!options || options.nodupes !== false) {
    return utils.unique(matches);
  }

  return matches;
}
/**
 * Similar to the main function, but `pattern` must be a string.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.match(list, pattern[, options]);
 *
 * console.log(nm.match(['a.a', 'a.aa', 'a.b', 'a.c'], '*.a'));
 * //=> ['a.a', 'a.aa']
 * ```
 * @param {Array} `list` Array of strings to match
 * @param {String} `pattern` Glob pattern to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Array} Returns an array of matches
 * @api public
 */


nanomatch.match = function (list, pattern, options) {
  if (Array.isArray(pattern)) {
    throw new TypeError("expected pattern to be a string");
  }

  var unixify = utils.unixify(options);
  var isMatch = memoize("match", pattern, options, nanomatch.matcher);
  var matches = [];
  list = utils.arrayify(list);
  var len = list.length;
  var idx = -1;

  while (++idx < len) {
    var ele = list[idx];

    if (ele === pattern || isMatch(ele)) {
      matches.push(utils.value(ele, unixify, options));
    }
  } // if no options were passed, uniquify results and return


  if (typeof options === "undefined") {
    return utils.unique(matches);
  }

  if (matches.length === 0) {
    if (options.failglob === true) {
      throw new Error("no matches found for \"" + pattern + "\"");
    }

    if (options.nonull === true || options.nullglob === true) {
      return [options.unescape ? utils.unescape(pattern) : pattern];
    }
  } // if `opts.ignore` was defined, diff ignored list


  if (options.ignore) {
    matches = nanomatch.not(matches, options.ignore, options);
  }

  return options.nodupes !== false ? utils.unique(matches) : matches;
};
/**
 * Returns true if the specified `string` matches the given glob `pattern`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.isMatch(string, pattern[, options]);
 *
 * console.log(nm.isMatch('a.a', '*.a'));
 * //=> true
 * console.log(nm.isMatch('a.b', '*.a'));
 * //=> false
 * ```
 * @param {String} `string` String to match
 * @param {String} `pattern` Glob pattern to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns true if the string matches the glob pattern.
 * @api public
 */


nanomatch.isMatch = function (str, pattern, options) {
  if (typeof str !== "string") {
    throw new TypeError("expected a string: \"" + util.inspect(str) + "\"");
  }

  if (utils.isEmptyString(str) || utils.isEmptyString(pattern)) {
    return false;
  }

  var equals = utils.equalsPattern(options);

  if (equals(str)) {
    return true;
  }

  var isMatch = memoize("isMatch", pattern, options, nanomatch.matcher);
  return isMatch(str);
};
/**
 * Returns true if some of the elements in the given `list` match any of the
 * given glob `patterns`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.some(list, patterns[, options]);
 *
 * console.log(nm.some(['foo.js', 'bar.js'], ['*.js', '!foo.js']));
 * // true
 * console.log(nm.some(['foo.js'], ['*.js', '!foo.js']));
 * // false
 * ```
 * @param  {String|Array} `list` The string or array of strings to test. Returns as soon as the first match is found.
 * @param {String|Array} `patterns` One or more glob patterns to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns true if any patterns match `str`
 * @api public
 */


nanomatch.some = function (list, patterns, options) {
  if (typeof list === "string") {
    list = [list];
  }

  for (var i = 0; i < list.length; i++) {
    if (nanomatch(list[i], patterns, options).length === 1) {
      return true;
    }
  }

  return false;
};
/**
 * Returns true if every element in the given `list` matches
 * at least one of the given glob `patterns`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.every(list, patterns[, options]);
 *
 * console.log(nm.every('foo.js', ['foo.js']));
 * // true
 * console.log(nm.every(['foo.js', 'bar.js'], ['*.js']));
 * // true
 * console.log(nm.every(['foo.js', 'bar.js'], ['*.js', '!foo.js']));
 * // false
 * console.log(nm.every(['foo.js'], ['*.js', '!foo.js']));
 * // false
 * ```
 * @param  {String|Array} `list` The string or array of strings to test.
 * @param {String|Array} `patterns` One or more glob patterns to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns true if any patterns match `str`
 * @api public
 */


nanomatch.every = function (list, patterns, options) {
  if (typeof list === "string") {
    list = [list];
  }

  for (var i = 0; i < list.length; i++) {
    if (nanomatch(list[i], patterns, options).length !== 1) {
      return false;
    }
  }

  return true;
};
/**
 * Returns true if **any** of the given glob `patterns`
 * match the specified `string`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.any(string, patterns[, options]);
 *
 * console.log(nm.any('a.a', ['b.*', '*.a']));
 * //=> true
 * console.log(nm.any('a.a', 'b.*'));
 * //=> false
 * ```
 * @param  {String|Array} `str` The string to test.
 * @param {String|Array} `patterns` One or more glob patterns to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns true if any patterns match `str`
 * @api public
 */


nanomatch.any = function (str, patterns, options) {
  if (typeof str !== "string") {
    throw new TypeError("expected a string: \"" + util.inspect(str) + "\"");
  }

  if (utils.isEmptyString(str) || utils.isEmptyString(patterns)) {
    return false;
  }

  if (typeof patterns === "string") {
    patterns = [patterns];
  }

  for (var i = 0; i < patterns.length; i++) {
    if (nanomatch.isMatch(str, patterns[i], options)) {
      return true;
    }
  }

  return false;
};
/**
 * Returns true if **all** of the given `patterns`
 * match the specified string.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.all(string, patterns[, options]);
 *
 * console.log(nm.all('foo.js', ['foo.js']));
 * // true
 *
 * console.log(nm.all('foo.js', ['*.js', '!foo.js']));
 * // false
 *
 * console.log(nm.all('foo.js', ['*.js', 'foo.js']));
 * // true
 *
 * console.log(nm.all('foo.js', ['*.js', 'f*', '*o*', '*o.js']));
 * // true
 * ```
 * @param  {String|Array} `str` The string to test.
 * @param {String|Array} `patterns` One or more glob patterns to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns true if any patterns match `str`
 * @api public
 */


nanomatch.all = function (str, patterns, options) {
  if (typeof str !== "string") {
    throw new TypeError("expected a string: \"" + util.inspect(str) + "\"");
  }

  if (typeof patterns === "string") {
    patterns = [patterns];
  }

  for (var i = 0; i < patterns.length; i++) {
    if (!nanomatch.isMatch(str, patterns[i], options)) {
      return false;
    }
  }

  return true;
};
/**
 * Returns a list of strings that _**do not match any**_ of the given `patterns`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.not(list, patterns[, options]);
 *
 * console.log(nm.not(['a.a', 'b.b', 'c.c'], '*.a'));
 * //=> ['b.b', 'c.c']
 * ```
 * @param {Array} `list` Array of strings to match.
 * @param {String|Array} `patterns` One or more glob pattern to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Array} Returns an array of strings that **do not match** the given patterns.
 * @api public
 */


nanomatch.not = function (list, patterns, options) {
  var opts = extend({}, options);
  var ignore = opts.ignore;
  delete opts.ignore;
  list = utils.arrayify(list);
  var matches = utils.diff(list, nanomatch(list, patterns, opts));

  if (ignore) {
    matches = utils.diff(matches, nanomatch(list, ignore));
  }

  return opts.nodupes !== false ? utils.unique(matches) : matches;
};
/**
 * Returns true if the given `string` contains the given pattern. Similar
 * to [.isMatch](#isMatch) but the pattern can match any part of the string.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.contains(string, pattern[, options]);
 *
 * console.log(nm.contains('aa/bb/cc', '*b'));
 * //=> true
 * console.log(nm.contains('aa/bb/cc', '*d'));
 * //=> false
 * ```
 * @param {String} `str` The string to match.
 * @param {String|Array} `patterns` Glob pattern to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns true if the patter matches any part of `str`.
 * @api public
 */


nanomatch.contains = function (str, patterns, options) {
  if (typeof str !== "string") {
    throw new TypeError("expected a string: \"" + util.inspect(str) + "\"");
  }

  if (typeof patterns === "string") {
    if (utils.isEmptyString(str) || utils.isEmptyString(patterns)) {
      return false;
    }

    var equals = utils.equalsPattern(patterns, options);

    if (equals(str)) {
      return true;
    }

    var contains = utils.containsPattern(patterns, options);

    if (contains(str)) {
      return true;
    }
  }

  var opts = extend({}, options, {
    contains: true
  });
  return nanomatch.any(str, patterns, opts);
};
/**
 * Returns true if the given pattern and options should enable
 * the `matchBase` option.
 * @return {Boolean}
 * @api private
 */


nanomatch.matchBase = function (pattern, options) {
  if (pattern && pattern.indexOf("/") !== -1 || !options) return false;
  return options.basename === true || options.matchBase === true;
};
/**
 * Filter the keys of the given object with the given `glob` pattern
 * and `options`. Does not attempt to match nested keys. If you need this feature,
 * use [glob-object][] instead.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.matchKeys(object, patterns[, options]);
 *
 * var obj = { aa: 'a', ab: 'b', ac: 'c' };
 * console.log(nm.matchKeys(obj, '*b'));
 * //=> { ab: 'b' }
 * ```
 * @param {Object} `object` The object with keys to filter.
 * @param {String|Array} `patterns` One or more glob patterns to use for matching.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Object} Returns an object with only keys that match the given patterns.
 * @api public
 */


nanomatch.matchKeys = function (obj, patterns, options) {
  if (!utils.isObject(obj)) {
    throw new TypeError("expected the first argument to be an object");
  }

  var keys = nanomatch(Object.keys(obj), patterns, options);
  return utils.pick(obj, keys);
};
/**
 * Returns a memoized matcher function from the given glob `pattern` and `options`.
 * The returned function takes a string to match as its only argument and returns
 * true if the string is a match.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.matcher(pattern[, options]);
 *
 * var isMatch = nm.matcher('*.!(*a)');
 * console.log(isMatch('a.a'));
 * //=> false
 * console.log(isMatch('a.b'));
 * //=> true
 * ```
 * @param {String} `pattern` Glob pattern
 * @param {Object} `options` See available [options](#options) for changing how matches are performed.
 * @return {Function} Returns a matcher function.
 * @api public
 */


nanomatch.matcher = function matcher(pattern, options) {
  if (utils.isEmptyString(pattern)) {
    return function () {
      return false;
    };
  }

  if (Array.isArray(pattern)) {
    return compose(pattern, options, matcher);
  } // if pattern is a regex


  if (pattern instanceof RegExp) {
    return test(pattern);
  } // if pattern is invalid


  if (!utils.isString(pattern)) {
    throw new TypeError("expected pattern to be an array, string or regex");
  } // if pattern is a non-glob string


  if (!utils.hasSpecialChars(pattern)) {
    if (options && options.nocase === true) {
      pattern = pattern.toLowerCase();
    }

    return utils.matchPath(pattern, options);
  } // if pattern is a glob string


  var re = nanomatch.makeRe(pattern, options); // if `options.matchBase` or `options.basename` is defined

  if (nanomatch.matchBase(pattern, options)) {
    return utils.matchBasename(re, options);
  }

  function test(regex) {
    var equals = utils.equalsPattern(options);
    var unixify = utils.unixify(options);
    return function (str) {
      if (equals(str)) {
        return true;
      }

      if (regex.test(unixify(str))) {
        return true;
      }

      return false;
    };
  } // create matcher function


  var matcherFn = test(re); // set result object from compiler on matcher function,
  // as a non-enumerable property. useful for debugging

  utils.define(matcherFn, "result", re.result);
  return matcherFn;
};
/**
 * Returns an array of matches captured by `pattern` in `string, or
 * `null` if the pattern did not match.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.capture(pattern, string[, options]);
 *
 * console.log(nm.capture('test/*.js', 'test/foo.js'));
 * //=> ['foo']
 * console.log(nm.capture('test/*.js', 'foo/bar.css'));
 * //=> null
 * ```
 * @param {String} `pattern` Glob pattern to use for matching.
 * @param {String} `string` String to match
 * @param {Object} `options` See available [options](#options) for changing how matches are performed
 * @return {Boolean} Returns an array of captures if the string matches the glob pattern, otherwise `null`.
 * @api public
 */


nanomatch.capture = function (pattern, str, options) {
  var re = nanomatch.makeRe(pattern, extend({
    capture: true
  }, options));
  var unixify = utils.unixify(options);

  function match() {
    return function (string) {
      var match = re.exec(unixify(string));

      if (!match) {
        return null;
      }

      return match.slice(1);
    };
  }

  var capture = memoize("capture", pattern, options, match);
  return capture(str);
};
/**
 * Create a regular expression from the given glob `pattern`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.makeRe(pattern[, options]);
 *
 * console.log(nm.makeRe('*.js'));
 * //=> /^(?:(\.[\\\/])?(?!\.)(?=.)[^\/]*?\.js)$/
 * ```
 * @param {String} `pattern` A glob pattern to convert to regex.
 * @param {Object} `options` See available [options](#options) for changing how matches are performed.
 * @return {RegExp} Returns a regex created from the given pattern.
 * @api public
 */


nanomatch.makeRe = function (pattern, options) {
  if (pattern instanceof RegExp) {
    return pattern;
  }

  if (typeof pattern !== "string") {
    throw new TypeError("expected pattern to be a string");
  }

  if (pattern.length > MAX_LENGTH) {
    throw new Error("expected pattern to be less than " + MAX_LENGTH + " characters");
  }

  function makeRe() {
    var opts = utils.extend({
      wrap: false
    }, options);
    var result = nanomatch.create(pattern, opts);
    var regex = toRegex(result.output, opts);
    utils.define(regex, "result", result);
    return regex;
  }

  return memoize("makeRe", pattern, options, makeRe);
};
/**
 * Parses the given glob `pattern` and returns an object with the compiled `output`
 * and optional source `map`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.create(pattern[, options]);
 *
 * console.log(nm.create('abc/*.js'));
 * // { options: { source: 'string', sourcemap: true },
 * //   state: {},
 * //   compilers:
 * //    { ... },
 * //   output: '(\\.[\\\\\\/])?abc\\/(?!\\.)(?=.)[^\\/]*?\\.js',
 * //   ast:
 * //    { type: 'root',
 * //      errors: [],
 * //      nodes:
 * //       [ ... ],
 * //      dot: false,
 * //      input: 'abc/*.js' },
 * //   parsingErrors: [],
 * //   map:
 * //    { version: 3,
 * //      sources: [ 'string' ],
 * //      names: [],
 * //      mappings: 'AAAA,GAAG,EAAC,kBAAC,EAAC,EAAE',
 * //      sourcesContent: [ 'abc/*.js' ] },
 * //   position: { line: 1, column: 28 },
 * //   content: {},
 * //   files: {},
 * //   idx: 6 }
 * ```
 * @param {String} `pattern` Glob pattern to parse and compile.
 * @param {Object} `options` Any [options](#options) to change how parsing and compiling is performed.
 * @return {Object} Returns an object with the parsed AST, compiled string and optional source map.
 * @api public
 */


nanomatch.create = function (pattern, options) {
  if (typeof pattern !== "string") {
    throw new TypeError("expected a string");
  }

  function create() {
    return nanomatch.compile(nanomatch.parse(pattern, options), options);
  }

  return memoize("create", pattern, options, create);
};
/**
 * Parse the given `str` with the given `options`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.parse(pattern[, options]);
 *
 * var ast = nm.parse('a/{b,c}/d');
 * console.log(ast);
 * // { type: 'root',
 * //   errors: [],
 * //   input: 'a/{b,c}/d',
 * //   nodes:
 * //    [ { type: 'bos', val: '' },
 * //      { type: 'text', val: 'a/' },
 * //      { type: 'brace',
 * //        nodes:
 * //         [ { type: 'brace.open', val: '{' },
 * //           { type: 'text', val: 'b,c' },
 * //           { type: 'brace.close', val: '}' } ] },
 * //      { type: 'text', val: '/d' },
 * //      { type: 'eos', val: '' } ] }
 * ```
 * @param {String} `str`
 * @param {Object} `options`
 * @return {Object} Returns an AST
 * @api public
 */


nanomatch.parse = function (pattern, options) {
  if (typeof pattern !== "string") {
    throw new TypeError("expected a string");
  }

  function parse() {
    var snapdragon = utils.instantiate(null, options);
    parsers(snapdragon, options);
    var ast = snapdragon.parse(pattern, options);
    utils.define(ast, "snapdragon", snapdragon);
    ast.input = pattern;
    return ast;
  }

  return memoize("parse", pattern, options, parse);
};
/**
 * Compile the given `ast` or string with the given `options`.
 *
 * ```js
 * var nm = require('nanomatch');
 * nm.compile(ast[, options]);
 *
 * var ast = nm.parse('a/{b,c}/d');
 * console.log(nm.compile(ast));
 * // { options: { source: 'string' },
 * //   state: {},
 * //   compilers:
 * //    { eos: [Function],
 * //      noop: [Function],
 * //      bos: [Function],
 * //      brace: [Function],
 * //      'brace.open': [Function],
 * //      text: [Function],
 * //      'brace.close': [Function] },
 * //   output: [ 'a/(b|c)/d' ],
 * //   ast:
 * //    { ... },
 * //   parsingErrors: [] }
 * ```
 * @param {Object|String} `ast`
 * @param {Object} `options`
 * @return {Object} Returns an object that has an `output` property with the compiled string.
 * @api public
 */


nanomatch.compile = function (ast, options) {
  if (typeof ast === "string") {
    ast = nanomatch.parse(ast, options);
  }

  function compile() {
    var snapdragon = utils.instantiate(ast, options);
    compilers(snapdragon, options);
    return snapdragon.compile(ast, options);
  }

  return memoize("compile", ast.input, options, compile);
};
/**
 * Clear the regex cache.
 *
 * ```js
 * nm.clearCache();
 * ```
 * @api public
 */


nanomatch.clearCache = function () {
  nanomatch.cache.__data__ = {};
};
/**
 * Compose a matcher function with the given patterns.
 * This allows matcher functions to be compiled once and
 * called multiple times.
 */


function compose(patterns, options, matcher) {
  var matchers;
  return memoize("compose", String(patterns), options, function () {
    return function (file) {
      // delay composition until it's invoked the first time,
      // after that it won't be called again
      if (!matchers) {
        matchers = [];

        for (var i = 0; i < patterns.length; i++) {
          matchers.push(matcher(patterns[i], options));
        }
      }

      var len = matchers.length;

      while (len--) {
        if (matchers[len](file) === true) {
          return true;
        }
      }

      return false;
    };
  });
}
/**
 * Memoize a generated regex or function. A unique key is generated
 * from the `type` (usually method name), the `pattern`, and
 * user-defined options.
 */


function memoize(type, pattern, options, fn) {
  var key = utils.createKey(type + "=" + pattern, options);

  if (options && options.cache === false) {
    return fn(pattern, options);
  }

  if (cache.has(type, key)) {
    return cache.get(type, key);
  }

  var val = fn(pattern, options);
  cache.set(type, key, val);
  return val;
}
/**
 * Expose compiler, parser and cache on `nanomatch`
 */


nanomatch.compilers = compilers;
nanomatch.parsers = parsers;
nanomatch.cache = cache;
/**
 * Expose `nanomatch`
 * @type {Function}
 */

exports = nanomatch;
export default exports;